import React, { useState } from "react";
import SubscriptionInfo from "../../pages/subscription/SubscriptionInfo";
import { MONTHLY_PLAN, YEARLY_PLAN } from "../../constants";
import Switch from "../switch";
import { useTranslation } from "react-i18next";
import CountdownBanner from "./CountDown";

const Button = ({ children, variant = "default", onPress, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  variant = variant === "default" && props.disabled ? "outline" : variant;

  return (
    <button
      onClick={onPress}
      style={{
        backgroundColor: isHovered
          ? variant === "default"
            ? "#1d4ed8" // Darker blue on hover for default variant
            : "#white" // Light gray for other variants
          : variant === "default"
          ? "#2563eb"
          : "white",
        height: "44px",
        borderRadius: "16px",
        color: variant === "default" ? "white" : "#000",
        fontSize: "16px",
        border: variant === "default" ? "" : "1px solid #eee",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {children}
    </button>
  );
};

const Card = ({ children }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden">
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">{children}</div>
);

const CardTitle = ({ children }) => (
  <h3 className="text-lg font-semibold text-gray-900">{children}</h3>
);

const CardDescription = ({ children }) => (
  <p className="mt-1 text-sm text-gray-500">{children}</p>
);

const CardContent = ({ children }) => (
  <div className="px-6 py-4">{children}</div>
);

const PRICING_TYPE = {
  SUBSCRIPTION: "subscription",
  PRICING: "pricing",
};

const Pricing = ({ type = PRICING_TYPE.SUBSCRIPTION, user }) => {
  const { subscription, pricing } = user;
  const { credits } = subscription;
  const { maxPro, maxFree } = credits;
  const {
    originalPriceMonthly,
    priceMonthly,
    freeMonths,
    monthlyUrl,
    yearlyUrl,
    discount: { percentage, endDate, active },
  } = pricing;

  const isPro = subscription.plan === "pro";

  const [isYearly, setIsYearly] = useState(false);

  const { t } = useTranslation();

  const pricingPlans = [
    {
      name: t("pages.subscription.free"),
      description: t("pages.subscription.forExplorers"),
      monthlyPrice: 0,
      yearlyPrice: 0,
      credits: "",
      features: [
        `${Math.round(maxFree * 1000)} ${t("pages.subscription.creditsIncluded")}`,
      ],
    },
    {
      name: t("pages.subscription.pro"),
      description: t("pages.subscription.forDevelopers"),
      originalMonthlyPrice: originalPriceMonthly,
      monthlyPrice: priceMonthly,
      yearlyPrice: priceMonthly * (12 - freeMonths),
      credits: "",
      features: [`${t("pages.subscription.upTo", { credits: Math.round(maxPro * 1000) })}`],
    },
  ];

  const calculateDiscount = (original, discounted) => {
    return Math.round(((original - discounted) / original) * 100);
  };

  // #region Subscription

  const onSubscribe = () => {
    // Handle subscription
    if (isYearly) {
      window.open(YEARLY_PLAN + user.email, "_blank");
    } else {
      window.open(MONTHLY_PLAN + user.email, "_blank");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-4">
        {" "}
        {type === PRICING_TYPE.SUBSCRIPTION
          ? t("pages.subscription.title")
          : t("pages.subscription.pricing")}
      </h1>
      {type === PRICING_TYPE.SUBSCRIPTION && (
        <p className="text-xl mb-8">{t("pages.subscription.subtitle")}</p>
      )}

      <div
        style={{
          width: "100%",
        }}
      >
        <SubscriptionInfo user={user} />
      </div>

      {/* <div className="flex items-center justify-end mb-8">
        <span className={`mr-2 ${isYearly ? "text-gray-500" : ""}`}>
          {t("pages.subscription.monthly")}
        </span>
        <Switch
          checked={isYearly}
          onCheckedChange={() => setIsYearly(!isYearly)}
        />
        <span className={`ml-2 ${isYearly ? "" : "text-gray-500"}`}>
          {t("pages.subscription.yearly")}
        </span>
        <span className="ml-2 bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
          {`${freeMonths} ${t("pages.subscription.monthsFree")}`}
        </span>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {pricingPlans.map((plan, index) => (
          <Card key={index}>
            {/* {plan.name === t("pages.subscription.pro") && !isPro && endDate && (
              <CountdownBanner endDate={endDate} discount={percentage} />
            )} */}
            <CardHeader>
              <CardTitle>{plan.name}</CardTitle>
              <CardDescription>{plan.description}</CardDescription>
            </CardHeader>
            <CardContent>
              {plan.originalMonthlyPrice ? (
                <div className="mb-2">
                  <span className="text-sm line-through text-gray-500">
                    $
                    {isYearly
                      ? ((plan.originalMonthlyPrice * 10) / 12).toFixed(2)
                      : plan.originalMonthlyPrice.toFixed(2)}
                  </span>
                  <span className="ml-2 bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
                    {calculateDiscount(
                      plan.originalMonthlyPrice,
                      plan.monthlyPrice
                    )}
                    {`${t("pages.subscription.OFF")}`}
                  </span>
                </div>
              ) : (
                <div className="mb-2">
                  <span className="text-sm line-through text-gray-500">-</span>
                </div>
              )}
              <div className="text-4xl font-bold mb-2">
                $
                {isYearly
                  ? (plan.yearlyPrice / 12).toFixed(2)
                  : plan.monthlyPrice.toFixed(2)}
                <span className="text-sm font-normal">
                  /
                  {isYearly
                    ? t("pages.subscription.month")
                    : t("pages.subscription.month")}
                </span>
              </div>
              <p className="text-sm text-gray-500 mb-4">
                {plan.monthlyPrice === 0
                  ? t("pages.subscription.forever")
                  : `${
                      isYearly
                        ? t("pages.subscription.billedYearly")
                        : t("pages.subscription.billedMonthly")
                    }`}
              </p>
              <Button
                className="w-full mb-4"
                variant={plan.name === "Pro" ? "default" : "outline"}
                onPress={onSubscribe}
                disabled={plan.name === "Free" || isPro}
              >
                {plan.name === "Pro"
                  ? isPro
                    ? t("pages.subscription.currentPlan")
                    : t("pages.subscription.subscribe")
                  : t("pages.subscription.currentPlan")}
              </Button>
              <p className="text-sm font-medium mb-2">{plan.credits}</p>
              <ul className="space-y-2">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center text-sm">
                    <svg
                      className="mr-2 h-4 w-4 text-green-500"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
